var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'InterestFollowUpInfo',"size":"xl","headerText":_vm.$t('InterestFollowUps.data'),"headerIcon":_vm.interestFollowUp.icon}},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.interestFollowUp.fullCode,"title":_vm.$t('InterestFollowUps.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.interestFollowUp.interestFollowUpTitleAr,"title":_vm.$t('InterestFollowUps.titleAr'),"imgName":'interestFollowUps.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.interestFollowUp.interestFollowUpTitleEn,"title":_vm.$t('InterestFollowUps.titleEn'),"imgName":'interestFollowUps.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.interestFollowUp.interestFollowUpTypeNameCurrent,"title":_vm.$t('general.type'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.interestFollowUp.interestFollowUpNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.interestFollowUp.educationalCategoryInterestData
          ? _vm.interestFollowUp.educationalCategoryInterestData
              .educationalCategoryInterestDescriptionCurrent
          : '',"title":_vm.$t('Interests.title'),"imgName":'interestFollowUps.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.interestFollowUp.educationalCategoryInterestData
          ? _vm.interestFollowUp.educationalCategoryInterestData
              .joiningTypeNameCurrent
          : '',"title":_vm.$t('InterestFollowUps.joiningType'),"imgName":'interestFollowUps.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.interestFollowUp.educationalCategoryOtherInterestData
          ? _vm.interestFollowUp.educationalCategoryOtherInterestData
              .educationalCategoryOtherInterestNameCurrent
          : '',"title":_vm.$t('InterestFollowUps.dataOther'),"imgName":'interestFollowUps.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }